<template>
    <div class="chart-holder">
        <div class="l-padded datepicker">
            <DateRangeInput v-model="customRange" @input="loadHistoryCustom" />
        </div>

        <div class="chart">
            <div v-if="isLoading" class="l-stack l-center l-padded">
                <Spinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <template v-else-if="hasData">
                <ApexCharts
                    height="420px"
                    :options="chartOptions"
                    :series="series"
                />

                <div class="l-padded">
                    <p>
                        {{ $t('open') }}:
                        <strong>{{ totalTimeOpen }}</strong>
                    </p>

                    <p>
                        {{ $t('closed') }}:
                        <strong>{{ totalTimeClosed }}</strong>
                    </p>
                </div>
            </template>

            <div v-else class="l-stack l-center l-padded">
                {{ $t('nodata') }}
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import Spinner from 'vue-simple-spinner'
import ApexCharts from 'vue-apexcharts'

import { httpHelper } from '@/utils'

import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'

const { VUE_APP_COLOR_PRIMARY: colorPrimary } = process.env

export default {
    name: 'DigitalInputChartView',
    components: {
        ApexCharts,
        DateRangeInput,
        Spinner,
    },
    mixins: [ChartHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        dataType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            series: [
                {
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    type: 'rangeBar',
                    events: {
                        scrolled: (_, { xaxis: { min, max } }) => {
                            this.zoomRange = [min, max]
                        },
                        zoomed: (_, { xaxis: { min, max } }) => {
                            this.zoomRange = [min, max]
                        },
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                colors: [colorPrimary],
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                    },
                },
                tooltip: {
                    shared: false,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            response: null,
            isLoading: false,
            zoomRange: [],
        }
    },
    computed: {
        hasData() {
            return !!this.series[0].data.length
        },
        totalTimeClosed() {
            return this.getTotalTime(false, ...this.zoomRange)
        },
        totalTimeOpen() {
            return this.getTotalTime(true, ...this.zoomRange)
        },
    },
    watch: {
        dataType() {
            if (this.response) {
                this.addHistoryDataFromResponse(this.response)
            }
        },
    },
    methods: {
        loadHistoryCustom(dates) {
            this.loadHistoryFromDateRange({
                startDate: moment(dates.startDate),
                endDate: moment(dates.endDate),
            })
            this.chartOptions.xaxis.min = dates.startDate
            this.chartOptions.xaxis.max = dates.endDate
        },
        async loadHistoryFromDateRange(dates) {
            this.isLoading = true
            this.zoomRange = []
            this.startDate = dates.startDate
            this.endDate = dates.endDate
            this.customRange = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
            }
            this.response = await this.loadData()
            this.addHistoryDataFromResponse(this.response)
            this.isLoading = false
        },
        async loadData() {
            let results = []

            let respBefore = httpHelper.get(
                [
                    'measurements/?',
                    'timestamp_max=' +
                        encodeURIComponent(this.startDate.format()),
                    '&tracker=' + this.id,
                    '&fields=timestamp,sensor_data',
                    '&limit=1',
                ].join('')
            )
            let respAfter = httpHelper.get(
                [
                    'measurements/?',
                    'timestamp_min=' +
                        encodeURIComponent(this.endDate.format()),
                    '&tracker=' + this.id,
                    '&fields=timestamp,sensor_data',
                    '&limit=1',
                ].join('')
            )
            let historyUrl = [
                'measurements/?',
                'timestamp_min=' + encodeURIComponent(this.startDate.format()),
                '&timestamp_max=' + encodeURIComponent(this.endDate.format()),
                '&tracker=' + this.id,
                '&fields=timestamp,sensor_data',
                '&limit=' + process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST,
            ].join('')

            while (historyUrl) {
                const { data } = await httpHelper.get(historyUrl)
                historyUrl = data.next || null
                results = [...results, ...data.results]
            }

            let lastBefore = await respBefore
            lastBefore = lastBefore.data.results[0]
            if (lastBefore !== undefined) {
                lastBefore.timestamp = this.startDate.format()
                results = [...results, lastBefore]
            }

            let firstAfter = await respAfter
            firstAfter = firstAfter.data.results[0]
            if (firstAfter !== undefined) {
                firstAfter.timestamp = this.endDate.format()
                results = [firstAfter, ...results]
            }

            return results
        },
        addHistoryDataFromResponse(results) {
            const chartData = results
                .filter(item =>
                    Object.prototype.hasOwnProperty.call(
                        item.sensor_data,
                        this.dataType
                    )
                )
                .map(item => [item.timestamp, item.sensor_data[this.dataType]])
                .reverse()

            let dataOpen = []
            let dataClosed = []

            let lastSameDataIndex = 0
            while (lastSameDataIndex < chartData.length - 1) {
                let item = chartData[lastSameDataIndex]

                while (
                    lastSameDataIndex < chartData.length - 1 &&
                    chartData[lastSameDataIndex][1] === item[1]
                ) {
                    lastSameDataIndex++
                }

                const data = {
                    x: item[1] ? this.$t('open') : this.$t('closed'),
                    isOpen: item[1],
                    y: [
                        new Date(item[0]).getTime(),
                        new Date(chartData[lastSameDataIndex][0]).getTime(),
                    ],
                }

                if (item[1]) {
                    dataOpen.push(data)
                } else {
                    dataClosed.push(data)
                }
            }

            this.series = [
                {
                    data: [...dataOpen, ...dataClosed],
                },
            ]
        },
        getTotalTime(isOpen, limitL, limitR) {
            const duration = this.series[0].data
                .filter(item => item.isOpen === isOpen)
                .reduce((accDuration, item) => {
                    const dateStart = moment(
                        limitL ? Math.max(item.y[0], limitL) : item.y[0]
                    )
                    const dateEnd = moment(
                        limitR ? Math.min(item.y[1], limitR) : item.y[1]
                    )

                    if (dateStart < dateEnd) {
                        accDuration.add(
                            moment.duration(dateEnd.diff(dateStart))
                        )
                    }

                    return accDuration
                }, moment.duration(0))

            const hours = parseInt(duration.asHours())
            duration.subtract(hours, 'hours')
            const minutes = parseInt(duration.asMinutes())
            duration.subtract(minutes, 'minutes')
            const seconds = parseInt(duration.asSeconds())

            return (
                hours +
                'h ' +
                (minutes < 10 ? '0' : '') +
                minutes +
                'm ' +
                (seconds < 10 ? '0' : '') +
                seconds +
                's'
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "closed": "Closed",
        "nodata": "No data for this time period",
        "open": "Open"
    },
    "de": {
        "closed": "Geschlossen",
        "nodata": "Keine Daten für diese Zeitperiode",
        "open": "Offen"
    },
    "it": {
        "closed": "Closed",
        "nodata": "Nessun dato per questo periodo di tempo",
        "open": "Open"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-holder {
    display: flex;
    @include respond-to('for-tablet-down') {
        display: block;
    }
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
    @include respond-to('for-tablet-down') {
        border: none;
    }
}

input[type='checkbox'] {
    margin-left: 50px;
}

label {
    padding-left: 10px;
}

.datepicker {
    width: 20%;
    min-width: 350px;

    @include respond-to('for-tablet-down') {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }
}
</style>
